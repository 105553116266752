<template>
  <div class="latest-events width-100-percent text-center">
    <v-row class="px-2 px-md-0" justify="center">
      <v-col
        v-for="event in events"
        :key="event.id"
        class="mb-4"
        pa-2
        cols="12"
        sm="6"
        lg="4"
      >
        <EventSummary
          :item="event"
          :show-elevated-style="true"
          :data-test-id="dataTestIdEventSummary"
        />
      </v-col>
    </v-row>
    <div class="mt-4 px-8">
      <v-btn
        outlined
        :data-test-id="dataTestIdViewAllEvent"
        :style="displayIsMobile && { width: '100%' }"
        :rounded="roundedButton"
        :color="viewAllButtonColor"
        :to="`/engage/${partner}/events-dashboard`"
        >{{ buttonText }}</v-btn
      >
    </div>
  </div>
</template>

<script>
import { displayIsMobile } from "~/components/utilities/displayBreakpoints";

import EventSummary from "~/components/events/EventSummary";

export default {
  name: "LatestEvents",
  components: {
    EventSummary,
  },
  props: {
    events: { type: Array, default: () => [] },
    showLargeTitle: { type: Boolean, default: true },
    viewAllButtonColor: { type: String, default: "primary" },
    buttonText: { type: String, default: "View All Events" },
    roundedButton: { type: Boolean, default: true },
    dataTestIdEventSummary: {
      type: [String, null],
      default: null,
    },
    dataTestIdViewAllEvent: {
      type: [String, null],
      default: null,
    },
  },
  computed: {
    displayIsMobile,
    partner() {
      return this.$config.partner;
    },
  },
};
</script>

<style lang="scss" scoped>
.latest-events {
  .v-btn--outlined {
    border-width: 1px !important;
  }
}
</style>
