<template>
  <div class="institution-page-content">
    <LayoutGutters>
      <SelectedArticles
        class="mt-12"
        component-name="ArticleOverviewInstitution"
        title="Explore content"
        :articles="latestItems"
        :show-content-type="true"
        :large-title="true"
        :narrow-title-underline="true"
        :col-lg="6"
      />
      <v-div class="mx-5 py-1" text-center>
        <v-btn
          outlined
          color="primary"
          :to="`/engage/${partner}/search-dashboard`"
          :disabled="!latestItems.length"
          >Explore more content</v-btn
        >
      </v-div>
    </LayoutGutters>
    <MetricsInstitution :metrics="displayMetrics" class="mb-5" />
    <LayoutGutters v-if="latestEvents.length">
      <h2 class="institution-page-content__events-title">Events</h2>
      <NarrowTitleUnderline />
      <LatestEvents
        class="mt-12"
        title="Events"
        :narrow-title-underline="true"
        :large-title="true"
        :events="latestEvents"
        button-text="View more events"
        :rounded-button="false"
      />
    </LayoutGutters>
  </div>
</template>

<script>
import { mapState } from "vuex";

import LayoutGutters from "~/components/layout/LayoutGutters.vue";

import SelectedArticles from "~/components/articles/SelectedArticles.vue";
import MetricsInstitution from "~/components/page/institution/MetricsInstitution.vue";
import NarrowTitleUnderline from "~/components/page/institution/NarrowTitleUnderline";
import LatestEvents from "~/components/public-dashboard/LatestEvents.vue";

export default {
  name: "PageContentInstitution",
  components: {
    LayoutGutters,
    SelectedArticles,
    MetricsInstitution,
    NarrowTitleUnderline,
    LatestEvents,
  },
  props: {
    metrics: { type: Array, required: true },
    latestItems: { type: Array, required: true },
    latestEvents: { type: Array, required: true },
  },
  computed: {
    ...mapState({
      images: (state) => state.theme.images,
    }),
    partner() {
      return this.$config.partner;
    },
    displayMetrics() {
      return this.metrics
        .filter((metric) => metric.metricType !== "CITATION_COUNT")
        .map((metric) => {
          return {
            ...metric,
            icon: this.images.metricIcons[metric.metricType],
          };
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.institution-page-content {
  .v-btn--outlined {
    border-width: 1px !important;
  }
  &__events-title {
    font-size: 26px;
    font-weight: 600;
  }
}
</style>
