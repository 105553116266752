<template>
  <div class="narrow-title-underline accent mt-4" :class="color"></div>
</template>

<script>
export default {
  name: "NarrowTitleUnderline",
  props: { color: { type: String, default: "accent" } },
};
</script>

<style lang="scss" scoped>
.narrow-title-underline {
  width: 72px;
  height: 2px;
}
</style>
