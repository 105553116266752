<template>
  <v-container
    class="metrics-institution mx-0"
    fluid
    align="center"
    justify="center"
  >
    <LayoutGutters>
      <v-row align="center" justify="space-between" class="px-2 py-3">
        <v-col cols="12" md="2">
          <h2 class="metrics-institution__title py-2 text-center">
            Key Metrics
          </h2>
        </v-col>
        <v-col
          v-for="(metric, index) in metrics"
          :key="index"
          class="metrics-institution__container px-0"
          cols="12"
          sm="4"
          md="3"
        >
          <dl class="d-flex justify-space-around">
            <HeadlineMetric
              class="metrics-institution__metric"
              :class="displayIsMobile && 'metrics-institution__metric--narrow'"
              :title="metric.description"
              :metric-icon="metric.icon"
              :value="metric.value"
              :has-more-data="index < metricsCount - 1"
              :is-horizontal="displayMetricsHorizontally"
              :spacing="displayMetricsHorizontally ? '100%' : '60%'"
              :is-dl="true"
            />
          </dl>
        </v-col>
      </v-row>
    </LayoutGutters>
  </v-container>
</template>

<script>
import { displayIsMobile } from "~/components/utilities/displayBreakpoints";
import LayoutGutters from "~/components/layout/LayoutGutters.vue";

import HeadlineMetric from "~/components/metrics/components/HeadlineMetric.vue";

export default {
  name: "MetricsInstitution",
  components: {
    HeadlineMetric,
    LayoutGutters,
  },
  props: {
    metrics: { type: Array, required: true },
  },
  data() {
    return {};
  },
  computed: {
    displayIsMobile,
    partner() {
      return this.$config.partner;
    },
    metricsCount() {
      return this.metrics.length;
    },
    displayMetricsHorizontally() {
      return !this.displayIsMobile;
    },
  },
};
</script>

<style lang="scss" scoped>
.metrics-institution {
  min-height: 160px;
  color: white;
  background: linear-gradient(#1e3d6f, #0e1e37);

  &__container {
    z-index: 1;
  }
  &__metric {
    &--narrow {
      max-width: 200px;
    }
  }
  &__title {
    font-size: 28px;
    font-weight: 400;
  }
}
</style>
