<template>
  <v-container fill-height fluid class="large-hero-institution pa-0">
    <div
      :style="{ backgroundImage: `url('${banner}')` }"
      class="large-hero-institution__image layer-2"
    />
    <div class="large-hero-institution__image-overlay layer-3" />

    <v-row align="center" justify="center" class="layer-3 pa-4">
      <v-col
        align="center"
        justify="center"
        class="large-hero-institution__inner-container"
      >
        <div
          v-if="logo"
          class="large-hero-institution__logo-container white pa-2"
        >
          <img :src="logo" alt height="100%" width="100%" />
        </div>
        <h1 v-if="title" class="inverted--text font-weight-light">
          {{ title }}
        </h1>
        <div class="large-hero-institution__search-container mt-6">
          <SearchInput
            :partner="partner"
            :show-placeholder="true"
            :label="label"
            :inverted="isInvertedTitle"
            :outlined="true"
            background="white"
            :solo="true"
          />
        </div>
        <h2
          v-if="description"
          class="large-hero-institution__tagline white--text"
        >
          {{ description }}
        </h2>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import SearchInput from "~/components/search/SearchInput.vue";

export default {
  name: "LargeHeroInstitution",
  components: {
    SearchInput,
  },
  props: {
    banner: { type: String, required: true },
    label: { type: String, required: true },
    description: { type: String, default: "" },
    isInvertedTitle: { type: Boolean, default: false },
    logo: { type: String, default: "" },
    title: { type: String, default: "" },
  },
  computed: {
    partner() {
      return this.$config.partner;
    },
    ...mapState({
      theme: (state) => state.theme,
      layout: (state) => state.theme.layout,
      images: (state) => state.theme.images,
    }),
  },
};
</script>

<style lang="scss" scoped>
.large-hero-institution {
  height: 392px;
  position: relative;

  &__image-overlay {
    position: absolute;
    background: #000;
    opacity: 0.65;
    height: 100%;
    width: 100%;
  }

  &__image {
    position: absolute;
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
  }

  &__inner-container {
    max-width: 637px;
  }

  &__logo-container {
    max-width: 300px;
    height: 100px;
  }

  &__search-container {
    max-width: 535px;
  }

  &__tagline {
    font-size: 20px;
    font-weight: 400;
  }
}
</style>
