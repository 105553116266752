import { render, staticRenderFns } from "./PageContentInstitution.vue?vue&type=template&id=53121c5b&scoped=true&"
import script from "./PageContentInstitution.vue?vue&type=script&lang=js&"
export * from "./PageContentInstitution.vue?vue&type=script&lang=js&"
import style0 from "./PageContentInstitution.vue?vue&type=style&index=0&id=53121c5b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53121c5b",
  null
  
)

export default component.exports