<template>
  <div class="selected-articles">
    <span
      v-if="announceResultsStatus"
      class="d-sr-only"
      role="status"
      aria-atomic="true"
      >{{ resultsStatus }}</span
    >

    <div v-if="showSelectedArticles" class="pa-2 pa-md-0">
      <v-row
        v-if="title"
        class="pb-2 ma-0 text-primary"
        justify="space-between"
        :class="headerUnderline"
      >
        <v-col class="pa-0" cols="12" sm="6">
          <h2 class="title font-weight-bold">
            <button
              class="d-sm-none selected-articles__title"
              :class="largeTitle && 'selected-articles__title--large'"
              :aria-expanded="showDetails"
              @click.prevent="onToggleDisplay"
            >
              {{ title }}
              <v-icon
                class="selected-articles__button-icon mt-1"
                color="primary"
                >{{ showDetails ? mdiChevronUp : mdiChevronDown }}</v-icon
              >
            </button>
            <div
              class="d-none d-sm-block selected-articles__title"
              :class="largeTitle && 'selected-articles__title--large'"
            >
              {{ title }}
            </div>
          </h2>
          <NarrowTitleUnderline v-if="narrowTitleUnderline" />
        </v-col>
        <span v-if="contentCount" aria-hidden="true">
          <strong>{{ contentCount }}</strong>
          {{ resultPluralText }}
        </span>
      </v-row>
      <ContainerGrid :no-padding="noPadding">
        <LayoutItems
          v-if="showDetails"
          ref="layoutItems"
          :items="articles"
          :show-content-type="showContentType"
          :component-name="componentName"
          cols="12"
          sm="6"
          md="6"
          :lg="colLg"
          :data-test-id="dataTestId"
        />
      </ContainerGrid>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mdiChevronUp, mdiChevronDown } from "@mdi/js";
import ContainerGrid from "~/components/layout/ContainerGrid";
import LayoutItems from "~/components/layout/LayoutItems";
import NarrowTitleUnderline from "~/components/page/institution/NarrowTitleUnderline";

export default {
  name: "SelectedArticles",
  components: {
    ContainerGrid,
    LayoutItems,
    NarrowTitleUnderline,
  },
  props: {
    title: { type: String, default: undefined },
    showContentType: { type: Boolean, default: false },
    componentName: { type: String, default: "ArticleSummary" },
    colLg: { type: Number, default: 4 },
    articles: { type: Array, default: () => [] },
    noPadding: { type: Boolean, default: false },
    contentCount: { type: Number, default: undefined },
    announceResultsUpdatedText: { type: Boolean, default: false },
    largeTitle: { type: Boolean, default: false },
    narrowTitleUnderline: { type: Boolean, default: false },
    dataTestId: {
      type: [String, null],
      default: null,
    },
  },
  data() {
    return {
      showDetails: true,
      // icons
      mdiChevronUp,
      mdiChevronDown,
    };
  },
  computed: {
    ...mapState({
      layout: (state) => state.theme.layout,
    }),
    headerUnderline() {
      return this.layout.headerUnderlinePrimaryColor;
    },
    resultPluralText() {
      return this.contentCount === 1 ? "result" : "results";
    },
    resultsStatus() {
      const updatedResultsText = this.announceResultsUpdatedText
        ? "Updated results. "
        : "";
      return `${updatedResultsText}${this.contentCount} ${this.resultPluralText}`;
    },
    showSelectedArticles() {
      return this.contentCount === undefined || this.contentCount !== 0;
    },
    announceResultsStatus() {
      return this.contentCount !== undefined;
    },
  },
  methods: {
    onToggleDisplay() {
      this.showDetails = !this.showDetails;
    },
    focusItem(index) {
      this.$refs.layoutItems.focusItem(index);
    },
  },
};
</script>

<style lang="scss" scoped>
.selected-articles {
  &__button {
    width: 100%;
    text-align: left;
  }

  &__button-icon {
    float: right;
  }

  &__title {
    &--large {
      font-size: 26px;
      font-weight: 600;
    }
  }
}
</style>
