<template>
  <v-div
    :class="`headline-metric ${invertedStyle} ${colsStyle} ${offsetStyle}`"
    :border-right-thick-inverted="
      inverted === true && hasMoreData !== false && isHorizontal === true
    "
    :border-bottom-thick-inverted="
      inverted === true && hasMoreData !== false && isHorizontal === false
    "
    :border-right-thick-primary="
      inverted === false && hasMoreData !== false && isHorizontal === true
    "
    :d-inline-block="isHorizontal === true"
    class="pa-4 text-center"
  >
    <component :is="isDl ? 'dd' : 'div'" class="display-1"
      >{{ value ? value.toLocaleString() : "0" }}{{ unit }}</component
    >
    <component
      :is="isDl ? 'dt' : 'div'"
      class="headline-metric__caption d-inline-flex align-center justify-center"
    >
      <img v-if="metricIcon" :src="metricIcon" alt class="mr-1" />
      {{ title }}
    </component>
  </v-div>
</template>

<script>
export default {
  props: {
    title: { type: String, required: true },
    value: { type: Number, required: true },
    unit: { type: String, default: "" },
    hasMoreData: { type: Boolean, default: false },
    isHorizontal: { type: Boolean, default: true },
    spacing: { type: [Number, String], default: "100%" },
    inverted: { type: Boolean, default: true },
    metricBold: { type: Boolean, default: false },
    cols: { type: Number, default: 0 },
    offset: { type: Number, default: 0 },
    isDl: { type: Boolean, default: false },
    metricIcon: { type: String, default: null },
  },
  computed: {
    invertedStyle() {
      return this.inverted ? "inverted--text" : "";
    },
    colsStyle() {
      return this.cols ? `col col-${this.cols}` : "";
    },
    offsetStyle() {
      return this.cols ? `offset-${this.offset}` : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.headline-metric {
  max-height: 84px;
  width: 100%;

  &__caption {
    line-height: 1rem;
    width: 100%;
  }
}
</style>
